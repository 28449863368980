@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.title-text {
    font-family: 'Press Start 2P';
}

.banner {
    padding: "10px";
    position: relative;
    z-index: 1;
}

.banner-link {
    font-size: 20px;
}

html {
    height: 100%;
}

.home-container {
    height: 100%;
    position: relative;
    background-position: top center;
    padding-bottom: 30px;
    padding-top: 30px;
}

.about-container {
    height: 100%;
    position: relative;
    background-position: top center;
    padding-bottom: 30px;
    padding-top: 30px;
}

.code-container {
    height: 100%;
    position: relative;
    background-position: top center;
    padding-bottom: 30px;
    padding-top: 30px;
}

.thoughts-container {
    height: 100%;
    position: relative;
    background-position: top center;
    padding-bottom: 30px;
    padding-top: 30px;
}

.dnd-container {
    height: 100%;
    position: relative;
    background-position: top center;
    padding-bottom: 30px;
    padding-top: 30px;
}

.home-top {
    z-index: 1;
    position: relative;
}

.home-bottom {
    z-index: 1;
    position: relative;
}

.contact-card {
    z-index: 1;
    position: relative;
}

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
}

.heyThere {
    color: white;
    font-size: 80px;
}

.imwill {
    font-size: 60px;
}

.d20 {
    height: 43vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .d20 {
        animation: App-logo-spin infinite 20s linear;
    }
}

.social-link {
    color: white 
}

.social-link:hover {
    color: lightgray
}

.about-me-title {
    color: white;
    font-size: 40px;
}

.white {
    color: white;
}

.about-section {
    z-index: 1;
    position: relative;
}

.code-section {
    z-index: 1;
    position: relative;
}

.thoughts-section {
    z-index: 1;
    position: relative;
}

.dnd-section {
    z-index: 1;
    position: relative;
}

.lefty {
    text-align: left;
}

.caption {
    font-size: 15px;
}

.resume-button {
    background-color: white;
    color: black;
    border: none;
    text-decoration: none;
    font-size: 20px;
}

.stack-bar {
    background-color: #212528;
    z-index: 2;
    outline: 2px solid black;
}

.stack-section {
    background-color: #212528;
    z-index: 2;
}

.gh-link {
    color: white; 
}

.gh-link:hover {
    color: lightgray;
}

.tabset {
    z-index: 2;
    color: white;
    background-color: #212528;
}

.dnd-icon {
    color: white;
}

.pagetitle {
    font-size: 50px;
}

.about-text {
    font-size: 23px;
}

.secret {
    opacity: 0.0;
    text-decoration: none;

}